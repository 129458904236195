import React, { FC, useState } from 'react';
import Accordion from 'react-tiny-accordion';
import classNames from 'classnames';

import Button from 'common/Button';
import Icon from 'common/IconCustom';
import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IHopefulAccordionProps } from './model';

import DElement from '../DElement';
import ShadowDElement from '../ShadowDElement';

import './HopefulAccordion.scss';

const HopefulAccordion: FC<IHopefulAccordionProps> = ({ items }) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const { isMobile } = useScreenRecognition();

  const onChangeIndex = (_, expanded: boolean, selectedIndex: number) => {
    setExpandedIndex(expanded ? selectedIndex : null);
  };

  return (
    <div className="hopeful-accordion" data-testid="HopefulAccordion">
      {items.map(
        (
          { properties: { title, subTitle, text, bgImage, shadowDElementsMobile, dElement } },
          idx
        ) => {
          const colorType = idx % 2 === 0 ? 'yellow' : 'orange';

          return (
            <div className="hopeful-accordion__item-wrapper" key={title}>
              <Accordion
                className={classNames('hopeful-accordion__item', {
                  [`hopeful-accordion__item_color-type-${colorType}`]: colorType,
                })}
                transitionDuration="300"
                onChange={onChangeIndex}
              >
                <div
                  data-header={
                    <Button
                      variant="icon"
                      classes="hopeful-accordion__item-header"
                      aria-expanded={idx === expandedIndex}
                    >
                      <div className="hopeful-accordion__item-header--icon">
                        <Icon icon="chevron-up" />
                        <Icon icon="chevron-down" />
                      </div>
                      <div>
                        <h3 className="hopeful-accordion__item-title">{title}</h3>
                        <DangerouslySetInnerHtml
                          className="hopeful-accordion__item-subtitle"
                          html={subTitle}
                        />
                      </div>
                    </Button>
                  }
                >
                  <div className="hopeful-accordion__item-content">
                    <DangerouslySetInnerHtml
                      className="hopeful-accordion__item-content-text"
                      html={text}
                    />
                    <div className="hopeful-accordion__item__d-elements">
                      {shadowDElementsMobile?.length ? (
                        <div className="hopeful-accordion__item__d-elements-shadow">
                          {shadowDElementsMobile.map((item, index) => {
                            let isWithPrefixLine = true;

                            if (isMobile && index === 0) {
                              isWithPrefixLine = false;
                            }

                            return (
                              <ShadowDElement
                                key={item.properties.text}
                                data={item}
                                sizeType={
                                  isMobile && shadowDElementsMobile.length !== 1 ? index + 1 : 2
                                }
                                opacityType={
                                  isMobile && shadowDElementsMobile.length !== 1 ? index + 1 : 3
                                }
                                colorType="brown"
                                withSuffixLine
                                withPrefixLine={isWithPrefixLine}
                              />
                            );
                          })}
                        </div>
                      ) : null}

                      {dElement?.[0] ? (
                        <DElement
                          data={dElement[0]}
                          sizeType="small"
                          colorType={idx % 2 === 0 ? 'orange' : 'yellow'}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </Accordion>

              <div className="hopeful-accordion__item__bg-image-wrapper">
                <GatsbyImage
                  image={bgImage[0].properties.image}
                  alt={bgImage[0].properties.imageAlt}
                  className="hopeful-accordion__item__bg-image"
                  objectFit="cover"
                />
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default HopefulAccordion;
