import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { ProjectPlanPageTypes } from '@shared/types/umbraco';
import classnames from 'classnames';

import { Container, Layout } from 'layout';
import SimpleBanner from 'components/SimpleBanner';
import BodyRenderer from 'common/BodyRenderer';
import ImageBlock from 'components/ImageBlock';
import TextBlock from 'common/TextBlock';
import Video from 'common/Video';
import HopefulMonument from 'components/HopefulMonument';
import WideBanner from 'components/WideBanner';
import TitleText from 'common/TitleText';
import WaterWork from 'components/WaterWork';
import WaterTales from 'components/WaterTales';

import './ProjectPlan.scss';

const articleBlocks = {
  'Text Block': ({ properties }, keyId) => <TextBlock key={keyId} text={properties.text} />,
  'Image Block': ({ properties }, keyId) => <ImageBlock key={keyId} {...properties} />,
  Video: ({ properties }, keyId) => (
    <Video className="project-plan-video" key={keyId} {...properties} />
  ),
  'Water Work': ({ properties }, keyId) => <WaterWork key={keyId} {...properties} />,
  'Water Tales': ({ properties }, keyId) => <WaterTales key={keyId} {...properties} />,
};

const ProjectPlan: FC<ProjectPlanPageTypes.IProperties> = ({
  pageContext: { breadCrumbs, link, searchUrl },
  data: {
    page: {
      nodes: [
        {
          banner,
          body,
          defaultCompositions,
          seoMetaKeywords,
          seoMetaTitle,
          seoMetaTitleTemplate,
          seoMetaDescription,
          seoExternalHreflangs,
          seoNoIndex,
          seoCanonicalUrl,
          alternateUrls,
          ogImageUrl,
          useTextBannerStyle,
          hopefulMonument,
          sourcesBlock,
          skipRinse,
          pageTheme,
          pageBackgroundImage,
          hideHeader,
          hideFooter,
        },
      ],
    },
  },
}) => {
  const { waiSettings } = defaultCompositions;
  const { description, cta, image, alignImage, gridList, color, accordion } =
    skipRinse?.[0]?.properties || {};
  const imageUrl = ogImageUrl || banner?.[0]?.properties?.image?.fallbackUrl;
  const imageAlt = banner?.[0]?.properties?.title;

  return (
    <Layout
      {...{
        ...defaultCompositions,
      }}
      seoMetaKeywords={seoMetaKeywords}
      seoMetaTitle={seoMetaTitle}
      seoMetaTitleTemplate={seoMetaTitleTemplate}
      seoNoIndex={seoNoIndex}
      seoMetaDescription={seoMetaDescription}
      seoExternalHreflangs={seoExternalHreflangs}
      seoCanonicalUrl={seoCanonicalUrl}
      skipText={waiSettings?.skipText}
      link={link}
      searchUrl={searchUrl}
      alternateUrls={alternateUrls}
      imageUrl={imageUrl}
      imageAlt={imageAlt}
      isHideHeader={hideHeader}
      isHideFooter={hideFooter}
      pageBackgroundImage={pageBackgroundImage}
      pageClassName={classnames('project-plan-page', {
        [`${pageTheme}`]: pageTheme,
      })}
    >
      {banner?.length ? (
        <SimpleBanner
          {...{ ...banner[0].properties }}
          breadCrumbs={breadCrumbs.slice(1)}
          breadCrumbSeparatorIcon="chevron-right"
          breadCrumbPopIndex={0}
          breadCrumbIsHideLastSeparator
          useTextBannerStyle={useTextBannerStyle}
        />
      ) : null}
      {body?.length ? (
        <div className="article-details-page__body">
          <Container fluid>
            <div className="article-details-page__main-column">
              <BodyRenderer
                bodyData={body}
                bodyStructure={articleBlocks}
                bodyItemProps={{
                  ...{
                    waiSettings,
                  },
                }}
              />
            </div>
          </Container>
        </div>
      ) : null}

      {hopefulMonument?.[0]?.properties ? <HopefulMonument data={hopefulMonument} /> : null}
      {skipRinse?.[0]?.properties ? (
        <div className="project-plan-page__banner">
          <Container fluid>
            <WideBanner
              description={description}
              cta={cta}
              image={image}
              alignImage={alignImage}
              gridList={gridList}
              color={color}
              accordion={accordion}
            />
          </Container>
        </div>
      ) : null}
      {sourcesBlock?.[0]?.properties ? (
        <div className="project-plan-page__sources">
          <TitleText data={sourcesBlock[0].properties} />
        </div>
      ) : null}
    </Layout>
  );
};
export const query = graphql`
  query allProjectPlanQuery($link: String = "") {
    page: allProjectPlan(filter: { link: { eq: $link } }) {
      nodes {
        seoMetaTitle
        seoMetaTitleTemplate
        seoMetaDescription
        seoMetaKeywords
        seoNoIndex
        seoExternalHreflangs {
          key
          value
        }
        seoCanonicalUrl {
          url
        }
        alternateUrls {
          lang
          url
          path
        }
        ogImageUrl
        defaultCompositions {
          footer {
            ...FragmentFooter
          }
          siteSettings {
            ...FragmentSiteSettingsComposition
          }
          brandSettings {
            ...FragmentBrandSettingsComposition
          }
          singupFormBaner {
            ...FragmentSignUpBanner
          }
          waiSettings {
            skipText
            ariaPrev
            ariaNext
          }
          warning {
            ...FragmentWarning
          }
          signUpPopup {
            ...FragmentSignUpPopup
          }
          signUpFormPopup {
            ...FragmentSignUpFormPopup
          }
          promoPlatformForm {
            ...FragmentPromoPlatformForm
          }
        }
        banner {
          ...FragmentSimpleBannerBigImage
        }
        body {
          ...FragmentArticleBody
        }
        hopefulMonument {
          ...FragmentHopefulMonument
        }
        useTextBannerStyle
        sourcesBlock {
          ...FragmentTitleText
        }
        skipRinse {
          ...FragmentWideBanner
        }
        pageTheme
        hideHeader
        hideFooter
        pageBackgroundImage {
          ...FragmentPageBackgroundImage
        }
      }
    }
  }
`;

export default ProjectPlan;
