import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import useScreenRecognition from 'hooks/useScreenRecognition';

import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import ShadowDElement from 'components/HopefulMonument/ShadowDElement';
import DElement from 'components/HopefulMonument/DElement';

import { IHopefulItemProps } from './models';
import './HopefulItem.scss';

const HopefulItem: FC<IHopefulItemProps> = ({ data, colorType }) => {
  const { text, title, shadowDElements, shadowDElementsMobile, bgImage, dElement, subTitle } =
    data?.properties;

  const { isMobile } = useScreenRecognition();
  const shadowDElementsToRender = isMobile ? shadowDElementsMobile : shadowDElements;

  return (
    <div
      data-testid="HopefulItem"
      className={classNames('hopeful-monument-item', {
        [`hopeful-monument-item__color-type-${colorType}`]: colorType,
      })}
    >
      <div className="hopeful-monument-item__bg-image-wrapper">
        <GatsbyImage
          image={bgImage[0].properties.image}
          alt={bgImage[0].properties.imageAlt}
          className="hopeful-monument-item__bg-image"
          objectFit="contain"
        />
      </div>

      <div className="hopeful-monument-item__wrapper">
        <div className="hopeful-monument-item__content">
          <h3 className="hopeful-monument-item__content-title">{title}</h3>
          <DangerouslySetInnerHtml
            className="hopeful-monument-item__content-subtitle"
            html={subTitle}
          />
          <DangerouslySetInnerHtml className="hopeful-monument-item__content-text" html={text} />
        </div>

        <div className="hopeful-monument-item__d-elements">
          {shadowDElementsToRender?.length ? (
            <div className="hopeful-monument-item__d-elements-shadow">
              {shadowDElementsToRender.map((item, index) => {
                let isWithPrefixLine = true;

                if (isMobile && index === 0) {
                  isWithPrefixLine = false;
                }

                return (
                  <ShadowDElement
                    key={item.properties.text}
                    data={item}
                    sizeType={isMobile && shadowDElementsToRender.length !== 1 ? index + 1 : 2}
                    opacityType={isMobile && shadowDElementsToRender.length !== 1 ? index + 1 : 3}
                    colorType="brown"
                    withSuffixLine
                    withPrefixLine={isWithPrefixLine}
                  />
                );
              })}
            </div>
          ) : null}
          {dElement?.[0] ? (
            <DElement
              data={dElement[0]}
              sizeType="small"
              colorType={colorType === 'orange' ? 'yellow' : 'orange'}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHopefulItem on THopefulMonumentItem {
    properties {
      text
      title
      subTitle
      dElement {
        ...FragmentDElement
      }
      shadowDElements {
        ...FragmentShadowDElement
      }
      shadowDElementsMobile {
        ...FragmentShadowDElement
      }
      bgImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default HopefulItem;
