import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, DangerouslySetInnerHtml } from 'layout';

import HopefulMainItem from 'components/HopefulMonument/HopefulMainItem';
import HopefulItem from 'components/HopefulMonument/HopefulItem';

import HopefulAccordion from './HopefulAccordion';
import { IHopefulMonumentProps } from './models';

import './HopefulMonument.scss';

const HopefulMonument: FC<IHopefulMonumentProps> = ({ data }) => {
  const { title, text, mainItem, items, accordionMode } = data[0].properties;

  return (
    <div data-testid="HopefulMonument" className="hopeful-monument">
      <Container fluid>
        <div className="text-block">
          <DangerouslySetInnerHtml className="hopeful-monument__title" html={title} />
          <DangerouslySetInnerHtml className="hopeful-monument__text" html={text} />
        </div>

        {mainItem?.[0]?.properties ? <HopefulMainItem data={mainItem} /> : null}

        {accordionMode ? (
          <HopefulAccordion items={items} />
        ) : (
          <div className="hopeful-monument__items">
            {items.map((item, index) => (
              <HopefulItem
                key={item.properties.title}
                data={item}
                colorType={index % 2 === 0 ? 'yellow' : 'orange'}
              />
            ))}
          </div>
        )}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHopefulMonument on THopefulMonument {
    properties {
      title
      text
      mainItem {
        ...FragmentHopefulMainItem
      }
      items {
        ...FragmentHopefulItem
      }
      accordionMode
    }
  }
`;

export default HopefulMonument;
