import React, { FC, useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import useClickOutside from 'hooks/useClickOutside';

import { IPopup } from './model';

import './Popup.scss';

const Popup: FC<IPopup> = ({ isOpen, onClose, children }) => {
  const [documentBody, setDocumentBody] = useState(null);
  const bodyOverflowStyle = useRef<string>(null);
  const popupContentRef = useRef<HTMLDivElement>(null);

  useClickOutside(popupContentRef, onClose);

  useEffect(() => {
    setDocumentBody(document.body);
    bodyOverflowStyle.current = document.body.style.overflow;

    return () => {
      setDocumentBody(null);
      document.body.style.overflow = bodyOverflowStyle.current;
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : bodyOverflowStyle.current;
  }, [isOpen]);

  return isOpen && documentBody
    ? ReactDOM.createPortal(
        <div className="popup" data-testid="Popup">
          <div className="popup__content" ref={popupContentRef}>
            {children}
          </div>
        </div>,
        documentBody
      )
    : null;
};

export default Popup;
