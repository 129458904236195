import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import { IShadowDElementProps } from './models';
import './ShadowDElement.scss';

const ShadowDElement: FC<IShadowDElementProps> = ({
  data,
  sizeType,
  opacityType,
  colorType,
  withSuffixLine,
  withPrefixLine,
}) => {
  const { text } = data?.properties;

  return (
    <div data-testid="ShadowDElement" className="shadow-d-element">
      <DangerouslySetInnerHtml
        className={classNames('shadow-d-element__content', {
          [`shadow-d-element__content-size-${sizeType}`]: sizeType,
          [`shadow-d-element__content-color-${colorType}`]: colorType,
          [`shadow-d-element__content-opacity-${opacityType}`]: opacityType,
          [`shadow-d-element__content-with-suffix-line`]: withSuffixLine,
          [`shadow-d-element__content-with-prefix-line`]: withPrefixLine,
        })}
        html={text}
      />
    </div>
  );
};

export const query = graphql`
  fragment FragmentShadowDElement on TShadowDElement {
    properties {
      text
    }
  }
`;

export default ShadowDElement;
