import React, { FC, useState, useCallback } from 'react';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import Popup from 'common/Popup';
import Button from 'common/Button';

import { IWaterTalesProps } from './model';

import './WaterTales.scss';

const WaterTales: FC<IWaterTalesProps> = ({
  title,
  description,
  ctaLabel,
  ctaAriaCloseLabel,
  pdf,
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const visibilityPopupHandler = useCallback(() => {
    setIsPopupOpen((prev) => !prev);
  }, []);

  return (
    <section className="water-tales" data-testid="WaterTales">
      <DangerouslySetInnerHtml className="water-tales__title" html={title} />
      <DangerouslySetInnerHtml className="water-tales__description" html={description} />
      <Button
        variant="secondary-link"
        iconSuffix="chevron-right"
        ariaLabel={ctaLabel}
        clickHandler={visibilityPopupHandler}
        classes="water-tales__cta"
      >
        {ctaLabel}
      </Button>
      <Popup isOpen={isPopupOpen} onClose={visibilityPopupHandler}>
        <iframe title={title} width="100%" height="100%" src={pdf.url} frameBorder="0" />
        <button
          type="button"
          className="water-tales__close-btn"
          aria-label={ctaAriaCloseLabel}
          onClick={visibilityPopupHandler}
        >
          +
        </button>
      </Popup>
    </section>
  );
};

export default WaterTales;
