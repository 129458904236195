import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'common/GatsbyImage';
import { DangerouslySetInnerHtml } from 'layout';
import ShadowDElement from 'components/HopefulMonument/ShadowDElement';
import DElement from 'components/HopefulMonument/DElement';

import useScreenRecognition from 'hooks/useScreenRecognition';

import { IHopefulMainItemProps } from './models';
import './HopefulMainItem.scss';

const HopefulMainItem: FC<IHopefulMainItemProps> = ({ data }) => {
  const { text, title, shadowDElements, shadowDElementsMobile, mainImage, bgImage, dElement } =
    data?.[0]?.properties;

  const { isMobile } = useScreenRecognition();
  const shadowDElementsToRender = isMobile ? shadowDElementsMobile : shadowDElements;

  return (
    <div data-testid="HopefulMainItem" className="hopeful-monument-main-item">
      <div className="hopeful-monument-main-item__bg-image-wrapper">
        <GatsbyImage
          image={bgImage[0].properties.image}
          alt={bgImage[0].properties.imageAlt}
          className="hopeful-monument-main-item__bg-image"
          objectFit="contain"
        />
      </div>

      <div className="hopeful-monument-main-item__content">
        <GatsbyImage
          image={mainImage[0].properties.image}
          alt={mainImage[0].properties.imageAlt}
          className="hopeful-monument-main-item__main-image"
          objectFit="contain"
        />
        <h3 className="hopeful-monument-main-item__content-title">{title}</h3>
        <DangerouslySetInnerHtml className="hopeful-monument-main-item__content-text" html={text} />
      </div>

      <div className="hopeful-monument-main-item__d-elements">
        {shadowDElementsToRender?.length ? (
          <div className="hopeful-monument-main-item__d-elements-shadow">
            {shadowDElementsToRender.map((item, index) => (
              <ShadowDElement
                key={item.properties.text}
                data={item}
                sizeType={index + 1}
                opacityType={index + 1}
                colorType="yellow"
                withSuffixLine
                withPrefixLine={isMobile}
              />
            ))}
          </div>
        ) : null}
        {dElement?.[0] ? (
          <DElement data={dElement[0]} sizeType="medium" colorType="yellow" />
        ) : null}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHopefulMainItem on THopefulMonumentMainItem {
    properties {
      text
      title
      dElement {
        ...FragmentDElement
      }
      shadowDElements {
        ...FragmentShadowDElement
      }
      shadowDElementsMobile {
        ...FragmentShadowDElement
      }
      bgImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
      mainImage {
        properties {
          image {
            ...FragmentGatsbyProps
            gatsbyImage {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

export default HopefulMainItem;
