const settings = {
  dots: false,
  className: 'center',
  centerMode: true,
  adaptiveHeight: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
  speed: 500,

  responsive: [
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 3,
        centerMode: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        centerMode: false,
      },
    },
    {
      breakpoint: 991,
      settings: {
        centerPadding: '20%',
      },
    },
    {
      breakpoint: 900,
      settings: {
        centerPadding: '25%',
      },
    },
    {
      breakpoint: 820,
      settings: {
        centerPadding: '20%',
      },
    },
    {
      breakpoint: 720,
      settings: {
        centerPadding: '15%',
      },
    },
    {
      breakpoint: 600,
      settings: {
        centerPadding: '12%',
      },
    },
    {
      breakpoint: 520,
      settings: {
        centerPadding: '8%',
      },
    },
    {
      breakpoint: 420,
      settings: {
        centerPadding: '4%',
      },
    },
  ],
};

export default settings;
